import svg4everybody from 'svg4everybody';
svg4everybody();

var init = () => {
    initNavigation();
    initFAQs();
};

document.addEventListener('DOMContentLoaded', function() {
    setTimeout(init, 0);
});

/******************************
    Navigation
******************************/
var initNavigation = () => {
    var nav = document.querySelector('article.event > header nav');

    initNavigationEvents(
        ...initNavigationOverlay(
            ...initNavigationCloseButton(
                ...initNavigationOpenButton(nav)
            )
        )
    );
};

var initNavigationEvents = (nav, openButton, closeButton) => {
    openButton.addEventListener('click', () => {
        nav.classList.add('open');
    });

    closeButton.addEventListener('click', () => {
        nav.classList.remove('open');
    });
};

var initNavigationOverlay = (nav, openButton, closeButton) => {
    var overlay = document.createElement('div'),
        aligner = document.createElement('div');

    overlay.classList.add('nav-overlay');
    nav.parentNode.insertBefore(overlay, nav);
    overlay.appendChild(aligner);

    aligner.classList.add('nav-aligner');
    aligner.appendChild(nav);

    return [overlay, openButton, closeButton];
};

var initNavigationOpenButton = (nav) => {
    var openButton = createSvgRef('Open Navigation', 'nav-open', 'navigation');

    nav.parentNode.insertBefore(openButton, nav);

    return [nav, openButton];
};

var initNavigationCloseButton = (nav, openButton) => {
    var closeButton = createSvgRef('Close Navigation', 'nav-close', 'navigation-close');

    nav.appendChild(closeButton, nav);

    return [nav, openButton, closeButton];
};

var createSvgRef = (title, id, ref) => {
    var button = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
        use = document.createElementNS('http://www.w3.org/2000/svg', 'use');

    button.setAttribute('role', 'img');
    button.setAttribute('title', title);
    button.setAttribute('id', id);

    use.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/img/svgrefs.svg#' + ref);
    button.appendChild(use);

    return button;
};

/******************************
    FAQs
******************************/

var initFAQs = () => {
    var faqs = document.querySelectorAll('section.faqs article');

    for (var index in faqs) {
        if (faqs[index] instanceof Element) {
            initFAQItem(faqs[index]);
        }
    }
};

var initFAQItem = (faq) => {
    var wrapper = document.createElement('div'),
        question = faq.querySelector('h1.question'),
        answer = faq.querySelector('div.answer');

    wrapper.classList.add('wrapper');
    wrapper.appendChild(answer);

    faq.classList.add('interactive');
    faq.appendChild(wrapper);

    wrapper.addEventListener('transitionend', () => {
        if (faq.classList.contains('open')) {
            wrapper.style.height = 'auto';
        }
    });

    question.addEventListener('click', () => {
        if (faq.classList.contains('open')) {
            faq.classList.remove('open');

            wrapper.style.height = answer.scrollHeight + 'px';

            setTimeout(() => {
                wrapper.style.height = null;
            }, 50);
        }

        else {
            faq.classList.add('open');
            wrapper.style.height = answer.scrollHeight + 'px';
        }
    });
};
